import { use } from 'i18next'
import { initReactI18next } from 'react-i18next'

const i18n = use(initReactI18next)
  .init({
    lng: 'zh-TW',
    fallbackLng: 'zh-TW',
    preload: ['zh-TW'],
    supportedLngs: ['zh-TW', 'zh-CN', 'en-US'],
    ns: [],
    resources: {
      'zh-TW': {
      },
      'zh-CN': {
      },
      'en-US': {
      },
    },
    keySeparator: false,
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
