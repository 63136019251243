import React, { useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { setCookie } from 'utils/cookie'
import { whitelistAPI, loginAPI } from 'apis/common'

const CLIENT_ID = '714402863795-4erefe28dp5uclnbant1o3eokootn2e8.apps.googleusercontent.com'
const REDIRECT_URI = `${window.location.origin}/callback`

const Callback = () => {
  const history = useHistory()

  const getWhitelist = useCallback(async () => {
    setCookie('whitelist', [])
    try {
      const whitelist = await whitelistAPI()
      setCookie('whitelist', whitelist.data)
    } catch (err) {
      console.log('err::', err)
      setCookie('whitelist', [])
    }
  }, [])
  useEffect(() => {
    const handleCode = () => {
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')
      let tokenData
      if (code) {
        fetch('https://www.googleapis.com/oauth2/v4/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `code=${code
          }&client_id=${CLIENT_ID}`
            + '&client_secret=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'
            + `&redirect_uri=${REDIRECT_URI}`
            + '&grant_type=authorization_code',
        })
          .then(response => response.json())
          .then(async (data) => {
            tokenData = data
            try {
              const loginRs = await loginAPI({
                token: data.id_token,
              })
              if (loginRs) {
                // 取得會員資訊
                fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                  headers: {
                    'Authorization': `Bearer ${tokenData.access_token}`,
                  },
                }).then(rs => rs.json()).then((userInfo) => {
                  console.log(`Welcome, ${userInfo.name}! Your email is ${userInfo.email}.`)
                  if (userInfo.email.endsWith('@yile.com.tw')) {
                    setCookie('oauth_code', code)
                    setCookie('email', userInfo.email)
                    history.push('/index')
                  } else {
                    history.push('/login')
                  }
                })
                // 取得白名單
                getWhitelist()
              }
            } catch (err) {
              console.log('err::', err)
              history.push('/login')
            }
          })
      } else {
        console.log('驗證 code 失敗')
        history.push('/login')
      }
    }

    handleCode()
  }, [getWhitelist, history])

  return (
    <div id="userInfo" />
  )
}

export default React.memo(Callback)
