import { axs } from 'utils/axios'

const common = {
  updateTypeAPI: async (payload) => {
    const data = await axs('/backstage/qa_logs/update', payload)
    return data
  },

  usersAPI: async (payload) => {
    const data = await axs('/backstage/users')
    return data
  },

  loginAPI: async (payload) => {
    const data = await axs('/auth/login', payload)
    return data
  },
  logoutAPI: async () => {
    const data = await axs('/auth/logout')
    return data
  },
  whitelistAPI: async () => {
    const data = await axs('/auth/whitelist')
    return data
  },
  chatAPI: async (payload) => {
    const data = await axs('/answers/chat', payload)
    return data
  },

}

export const {
  updateTypeAPI,
  usersAPI,
  loginAPI,
  logoutAPI,
  whitelistAPI,
  chatAPI,
} = common
export default common
