import React, {
  useCallback, useRef, useEffect, useState
} from 'react'
import { useDispatch } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

const PrivateRoute = (props) => {
  const history = useHistory()
  const isMounted = useRef(false)
  const dispatch = useDispatch()
  const [isAuthSuccess, setIsAuthSuccess] = useState(false)

  const {
    id, component: Component, ...rest
  } = props

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setIsAuthSuccess(true)
        // 將其導去 內頁
        history.push(history.location.pathname)
        return false
      } catch (err) {
        console.error('err::', err)
        return err
      }
    }
    if (!isAuthSuccess && !isMounted.current) {
      isMounted.current = true
      checkAuth()
    }
  }, [dispatch, history, isAuthSuccess])

  const routeContent = useCallback(() => (isAuthSuccess ? <Component /> : null),
    [isAuthSuccess])

  return (
    <Route
      id={id}
      {...rest}
      render={routeContent}
    />
  )
}

PrivateRoute.propTypes = {
  id: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object]).isRequired,
}

PrivateRoute.defaultProps = {
}

export default PrivateRoute
