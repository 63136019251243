import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isErrorStatus: false,
  refreshTokenIntervalIsInit: false,
  refreshTokenIntervalId: '',
}
const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setIsErrorStatus: (state, action) => {
      state.isErrorStatus = action.payload
    },
    setRefreshTokenIntervalIsInit: (state, action) => {
      state.refreshTokenIntervalIsInit = action.payload
    },
    setRefreshTokenIntervalId: (state, action) => {
      state.refreshTokenIntervalId = action.payload
    },
    initSystem: (state, action) => {
      state.isErrorStatus = false
      state.refreshTokenIntervalIsInit = false
      state.refreshTokenIntervalId = ''
    },
  },
})

export const {
  setIsErrorStatus, setRefreshTokenIntervalIsInit, initSystem, setRefreshTokenIntervalId,
} = systemSlice.actions
export default systemSlice.reducer
