import React from 'react'
import { StylesProvider } from '@mui/styles'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import store from './redux/store'
import * as serviceWorker from './serviceWorker'
import globalTheme from './globalTheme/muiGlobalTheme'
import App from './App'

createRoot(document.getElementById('root')).render(
  <ThemeProvider theme={globalTheme}>
    <StylesProvider injectFirst>
      <Provider store={store}>
        <CssBaseline />
        <App />
      </Provider>
    </StylesProvider>
  </ThemeProvider>
)
serviceWorker.unregister()
