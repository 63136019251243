import {
  createTheme, alpha, lighten, darken
} from '@mui/material/styles'

import vrbs from './variables.module.sass'

const globalTheme = createTheme({
  typography: {
    fontFamily: [
      '微軟正黑體',
      'Tahoma',
      'Arial',
    ].join(','),
    fontSize: 14,
  },
  palette: {
    text: {
      disabled: 'rgba(0,0,0,0.6)',
    },
    primary: {
      main: vrbs.cPrimary,
      light: lighten(vrbs.cPrimary, 0.2),
      dark: darken(vrbs.cPrimary, 0.2),
    },
    secondary: {
      main: vrbs.cSecondary,
      light: lighten(vrbs.cSecondary, 0.2),
      dark: darken(vrbs.cSecondary, 0.2),
    },
    error: {
      main: vrbs.cError,
      light: lighten(vrbs.cError, 0.2),
      dark: darken(vrbs.cError, 0.2),
    },
    warning: {
      main: vrbs.cWarning,
      light: lighten(vrbs.cWarning, 0.2),
      dark: darken(vrbs.cWarning, 0.2),
      contrastText: '#FFF',
    },
    info: {
      main: vrbs.cInfo,
      light: lighten(vrbs.cInfo, 0.2),
      dark: darken(vrbs.cInfo, 0.2),
    },
    success: {
      main: vrbs.cSuccess,
      light: lighten(vrbs.cSuccess, 0.2),
      dark: darken(vrbs.cSuccess, 0.2),
    },
    safe: {
      main: vrbs.cSafe,
      light: lighten(vrbs.cSafe, 0.2),
      dark: darken(vrbs.cSafe, 0.2),
      contrastText: '#FFF',
    },
    caution: {
      main: vrbs.cCaution,
      light: lighten(vrbs.cCaution, 0.2),
      dark: darken(vrbs.cCaution, 0.2),
      contrastText: '#FFF',
    },
    editable: {
      main: vrbs.cEditable,
      light: lighten(vrbs.cEditable, 0.2),
      dark: darken(vrbs.cEditable, 0.2),
      contrastText: '#FFF',
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          background: vrbs.cContrastBackground,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '0.5rem 1rem',
        },
        title: {
          fontSize: '1rem',
          fontWeight: 'bold',
          color: vrbs.cContrastText,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0.5rem 1rem',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 0,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: vrbs.cContrastText,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
        size: 'small',
        variant: 'outlined',
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          background: '#FFF',
          borderRadius: '0.3rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected.Mui-focusVisible, &.Mui-selected:hover': {
            backgroundColor: alpha(vrbs.cSecondary, 0.6),
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected:focus': {
            backgroundColor: alpha(vrbs.cPrimary, 0.8),
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            color: '#fff',
            pointerEvents: 'auto',
            backgroundColor: vrbs.cDisabled,

          },
        },

      },
    },
  },
})

export default globalTheme
