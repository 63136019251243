import React from 'react'
import { v4 as uuid } from 'uuid'

const routes = [
  { // 首頁
    key: uuid(), // 每一個節點都該有自己獨立的 key 值以供辨別
    id: 'index', // 對應後端頁面權限 id
    path: '/index', // router 顯示的 url path
    exact: true, // 是否為嚴格匹配路徑
    component: React.lazy(() => import('../views/home')),
    name: 'Home', // 選單顯示的名稱, 對應語系檔的 key
    visible: true, // 是否顯示在選單上
    enable: true, // 是否在前端啟用
  },
  { // 查詢列表
    key: uuid(), // 每一個節點都該有自己獨立的 key 值以供辨別
    id: 'list', // 對應後端頁面權限 id
    path: '/list', // router 顯示的 url path
    exact: true, // 是否為嚴格匹配路徑
    component: React.lazy(() => import('../views/list')),
    name: 'List', // 選單顯示的名稱, 對應語系檔的 key
    visible: true, // 是否顯示在選單上
    enable: true, // 是否在前端啟用
  },
  { // 登入
    key: uuid(),
    id: 'login',
    path: '/login',
    exact: true,
    component: React.lazy(() => import('../views/login')),
    name: 'Login',
    visible: true,
    enable: true,
  },
  { // callBack
    key: uuid(),
    id: 'callback',
    path: '/callback',
    exact: true,
    component: React.lazy(() => import('../views/callback')),
    name: 'Callback',
    visible: true,
    enable: true,
  },
]
export default routes
