/* eslint-disable no-alert */
import _axios from 'axios'
import history from 'router/history'

const axios = (baseURL = process.env.REACT_APP_API_URI, method = 'POST') => {
  const instance = _axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: false,
    baseURL,
    method,
    timeout: 20000,
    responseType: 'json',
  })

  return instance
}

const handleCatchError = (err) => {
  if (err.response) {
    if (err.response.status === 401) {
      // 未登入狀態直接導回登入頁
      if (history.location.pathname !== '/login') {
        // 在登入頁做 checkToken 時不顯示 notify 否則會重複
        alert('錯誤401')
      }
      history.push('/login')
    } else {
      alert(`【Error】${err.response.status}${err.response.statusText}`)
    }
  } else {
    alert(`【Error】${err.code}`)
  }
  return err
}

const axs = (apiName, sendData, method, timeout) => new Promise((resolve, reject) => {
  axios()({
    url: apiName,
    data: sendData,
    method,
    timeout,
    withCredentials: true,
  }).then(({ data, headers }) => {
    switch (data.retStatus.StatusCode) {
      case 10000:
        resolve(data.data === undefined ? true : data.data)
        break
      case 4401: // 登入逾期
        alert('登入逾期，請重新登入', 'error')
        history.push('/login')
        reject(data.retStatus)
        break
      default: {
        const { retStatus } = data
        const errMsg = [
          `X-Cloud-Trace-Context: ${headers['X-Cloud-Trace-Context']}`,
          `retStatus: ${JSON.stringify(retStatus)}`,
        ]
        alert(`【${retStatus.StatusCode}】${retStatus.StatusMsg}`, 'error', errMsg.join('\n'))
        reject(data.retStatus)
      }
    }
  }).catch((err) => {
    reject(handleCatchError(err))
  })
})

export default axios
export {
  axs
}
