import React, { Suspense } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import './App.sass'
import { Router } from 'react-router-dom'
import history from 'router/history'
import { addDataTableLangResources } from 'yile_data-table'
import { addSelectLangResources } from 'yile_select'
import { addNumberInputLangResources } from 'yile_number-input'
import { addCheckBoxGroupLangResources } from 'yile_check-box-group'
import { addDateTimeRangePickerLangResources } from 'yile_date-time-range-picker'
import { addTextFieldLangResources } from 'yile_text-field'
import PopDialog, { addPopDialogLangResources } from 'yile_pop-dialog'
import i18next from 'i18next'
import MainView from './components/mainView'
import localeMap from './dateFnsLocalization'
import './i18n'

function App() {
  addDataTableLangResources(i18next)
  addSelectLangResources(i18next)
  addNumberInputLangResources(i18next)
  addDateTimeRangePickerLangResources(i18next)
  addCheckBoxGroupLangResources(i18next)
  addTextFieldLangResources(i18next)
  addPopDialogLangResources(i18next)

  return (
    <div className="App">
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localeMap['zh-TW']}
      >
        <Router history={history}>
          <Suspense>
            <MainView />
          </Suspense>
        </Router>
        <PopDialog />
      </LocalizationProvider>
    </div>
  )
}

export default App
